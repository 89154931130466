import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { ChevronDropdown } from '../chevronDropdown';
import FilterModal from './filterModal';
import FilterItem from './filterItem';
import { Card } from '../../atoms/card';
import CompensationFilter from '../compensationFilter';

export const FilterOption = ({
  filterOptions,
  locationFilterOptions,
  updateFilters,
  tags,
  total,
  onInputChange,
  searchValue,
  resetFilters,
  hasAdvanceDesign,
  showJobAlert,
}) => {
  const filterDropdownRef = useRef();
  const [showMore, setShowMore] = useState(false);
  const [isTooManyFilters, setIsTooManyFilters] = useState(false);
  const [maxWidth, setMaxWidth] = useState(850);
  const [containerWidth, setContainerWidth] = useState(!showJobAlert ? 1070 : 900);
  const ref = useRef();

  const selectedCounts = useMemo(
    () =>
      tags.reduce((acc, item) => {
        acc.set(item.queryParam, item.values.length);
        return acc;
      }, new Map()),
    [tags],
  );

  useLayoutEffect(() => {
    if (!ref.current) return () => {};

    const rect = ref.current.getBoundingClientRect();
    setContainerWidth(rect.width);

    const handler = () => {
      const { width } = ref.current.getBoundingClientRect();
      setContainerWidth(width);
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  useLayoutEffect(() => {
    const allElementWidth = filterOptions
      .sort((item) => item.order)
      .map((_, index) => {
        const rect = ref.current?.querySelector?.(`#filter-${index}`)?.getBoundingClientRect();
        return rect?.width || 0;
      });

    const gaps = (allElementWidth.length - 1) * 8;
    const sumOfAllFilterWidth = allElementWidth.reduce((acc, item) => acc + item, 0) + gaps;
    const moreFilter = 110;
    if (sumOfAllFilterWidth === 0) return;

    if (sumOfAllFilterWidth <= containerWidth) {
      setMaxWidth(sumOfAllFilterWidth);
      setShowMore(false);
      setIsTooManyFilters(false);
    } else {
      let skipped = false;
      const newWidth = allElementWidth.reduce((acc, item) => {
        if (acc + item + 8 + moreFilter < containerWidth && !skipped) {
          return acc + item + 8;
        }
        skipped = true;

        return acc;
      }, 0);

      setMaxWidth(newWidth);
      setIsTooManyFilters(true);
    }
  }, [containerWidth, filterOptions]);

  return (
    <Flex
      data-testid="filter-option"
      ref={ref}
      width={!showJobAlert ? '100%' : ['100%', '50%', '100%']}
      sx={{ gap: ['0px', '0px', '8px'] }}
    >
      {containerWidth < 481 && (
        <FilterModal
          updateFilters={updateFilters}
          filterOptions={[...locationFilterOptions, ...filterOptions]}
          tags={tags}
          total={total}
          searchValue={searchValue}
          onInputChange={onInputChange}
          resetFilters={resetFilters}
          hasAdvanceDesign={hasAdvanceDesign}
        />
      )}
      {containerWidth > 479 && (
        <>
          <Box
            maxWidth={showMore ? '100%' : `${maxWidth}px`}
            sx={{
              overflow: 'hidden',
              zIndex: 1,
            }}
          >
            <Box
              flexWrap="wrap"
              height={showMore ? '72px' : '34px'}
              overflow={showMore ? 'initial' : 'hidden'}
              data-testid="filter-options"
              sx={{
                display: ['none', 'none', 'flex'],
                gap: '8px',
                button: {
                  padding: '0px',
                },
              }}
            >
              {filterOptions.map((filter, index) => (
                <Flex
                  id={`filter-${index}`}
                  data-testid={`filter-option-item-${index}`}
                  key={filter.name}
                  sx={{ boxSizing: 'border-box' }}
                  order={filter.order}
                >
                  <ChevronDropdown
                    selectedCount={selectedCounts.get(filter.queryParam)}
                    buttonText={filter.name}
                    fixedBottomPlacement
                    ref={filterDropdownRef}
                    hasAdvanceDesign={hasAdvanceDesign}
                  >
                    <Card
                      sx={{
                        width: '320px',
                        p: 0,
                      }}
                    >
                      <>
                        {filter.queryParam === 'compensation' ? (
                          <CompensationFilter
                            tag={tags.find((item) => item.queryParam === filter.queryParam) ?? { values: [] }}
                            filter={filter}
                            updateFilters={updateFilters}
                            hasAdvanceDesign={hasAdvanceDesign}
                            autoFocus
                          />
                        ) : (
                          <FilterItem
                            tag={tags.find((item) => item.queryParam === filter.queryParam) ?? { values: [] }}
                            filter={filter}
                            updateFilters={updateFilters}
                            hasAdvanceDesign={hasAdvanceDesign}
                            autoFocus
                          />
                        )}
                      </>
                    </Card>
                  </ChevronDropdown>
                </Flex>
              ))}
              {showMore === true && (
                <Flex
                  order={999999999}
                  sx={{
                    alignItems: ['left', 'center'],
                    minWidth: '130px',
                    p: '4px 16px',
                    justifyContent: 'space-between',
                    gap: '4px',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowMore()}
                >
                  Collapse filters
                </Flex>
              )}
            </Box>
          </Box>
          {!showMore && isTooManyFilters && (
            <Box
              order={999999999}
              sx={{
                alignItems: ['left', 'center'],
                minWidth: '110px',
                p: '4px 16px',
                justifyContent: 'space-between',
                gap: '4px',
                fontSize: '14px',
                cursor: 'pointer',
                height: '32px',
                display: ['none', 'none', 'flex'],
              }}
              onClick={() => setShowMore(true)}
            >
              More filters
            </Box>
          )}
        </>
      )}
    </Flex>
  );
};

FilterOption.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  locationFilterOptions: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  searchValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasAdvanceDesign: PropTypes.bool.isRequired,
  showJobAlert: PropTypes.bool.isRequired,
};

export default FilterOption;
