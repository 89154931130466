import isEqual from 'lodash.isequal';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from './usePrevious';
import useEncryptedRouter from './useEncryptedRouter';

const useRefreshDataOnQueryChange = ({ action, network }) => {
  const router = useEncryptedRouter();
  const previousQuery = usePrevious(router.query);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentQuery = router.query;
    const hasQueryChanged = !isEqual(previousQuery, currentQuery);
    if (previousQuery && hasQueryChanged) {
      dispatch(action({ network, query: currentQuery }));
    }
  }, [network, action, dispatch, previousQuery, router.query]);
};

export default useRefreshDataOnQueryChange;
