import {
  FloatingOverlay,
  FloatingPortal,
  autoPlacement as autoPlacementMiddleware,
  offset as setOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React, { cloneElement, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass/styled-components';
import { ChevronUp, ChevronDown } from 'lucide-react';
import Badge from '../../atoms/badge';

export const ChevronDropdown = ({
  trigger,
  triggerProps,
  placement,
  autoPlacement,
  offset,
  extraOptions,
  onOpen,
  onClose,
  children,
  buttonText,
  selectedCount,
  hasAdvanceDesign,
  ...rest
}) => {
  const didMount = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const middleware = [setOffset(offset), shift()];

  if (autoPlacement) {
    middleware.push(autoPlacementMiddleware());
  }

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware,
    ...extraOptions,
  });

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else if (isOpen && onOpen) {
      onOpen();
    } else if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, {
    bubbles: {
      outsidePress: false,
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

  const referenceProps = getReferenceProps();

  const onClick = (e) => {
    if (triggerProps.onClick) {
      triggerProps.onClick(e);
    }

    if (referenceProps.onClick) {
      referenceProps.onClick(e);
    }
  };

  return (
    <>
      {cloneElement(
        <Flex
          sx={{
            zIndex: 999,
            border: '1px solid',
            borderRadius: '4px',
            alignItems: ['left', 'center'],
            width: ['100%', 'auto'],
            p: '4px 16px',
            justifyContent: 'space-between',
            gap: '4px',
            borderColor: 'border.subtle',
            height: '32px',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              color: 'text.main',
            }}
          >
            {buttonText}
          </Text>
          {!selectedCount ? (
            <Box
              variant="icon"
              sx={{ stroke: 'text', color: 'text.main' }}
              strokeWidth="1.5"
              aria-hidden="true"
              size="16px"
              as={isOpen ? ChevronUp : ChevronDown}
            />
          ) : (
            <Badge hasAdvanceDesign={hasAdvanceDesign}>{selectedCount}</Badge>
          )}
        </Flex>,
        { ref: refs.setReference, ...triggerProps, ...getReferenceProps(), onClick },
      )}
      {isOpen && (
        <FloatingOverlay>
          <FloatingPortal>
            <Box
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 50,
              }}
              {...rest}
              {...getFloatingProps()}
            >
              {children}
            </Box>
          </FloatingPortal>
        </FloatingOverlay>
      )}
    </>
  );
};

ChevronDropdown.propTypes = {
  autoPlacement: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  trigger: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  offset: PropTypes.number,
  extraOptions: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  triggerProps: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  selectedCount: PropTypes.number,
  hasAdvanceDesign: PropTypes.bool.isRequired,
};

ChevronDropdown.defaultProps = {
  autoPlacement: false,
  placement: 'bottom-start',
  offset: 4,
  extraOptions: {},
  onOpen: null,
  onClose: null,
  triggerProps: {},
  selectedCount: 0,
};
