import { createSelector } from 'reselect';

export const locationSuggestionsRootSelector = (state) => state.locationSuggestions;

export const isLocationSuggestionsLoadingSelector = createSelector(
  locationSuggestionsRootSelector,
  (state) => state.loading,
);

export const locationSuggestionsSelector = createSelector(
  locationSuggestionsRootSelector,
  (state) => state.suggestions,
);

export const locationSuggestionsTermSelector = createSelector(locationSuggestionsRootSelector, (state) => state.term);
