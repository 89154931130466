import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectIsLoading } from '../redux/selectors/loading';
import { loadCustomFilters } from '../redux/actions/customFilterActions';
import { customFiltersSelector } from '../redux/selectors/customFilters';

/**
 * Get the custom filters for the site
 * @param network
 * @param useOrganizationPrefix - in some cases we may not need to prefix custom filters with 'organization.'
 * @returns {{isLoading: unknown, initialized, customFilters}}
 */
const useCustomFilters = ({ network }) => {
  const dispatch = useDispatch();
  const customFilters = useSelector(customFiltersSelector);
  const isLoadingCustomFilters = useSelector(makeSelectIsLoading('customFilters'));
  const { filters, initialized } = customFilters;
  const visibleCustomFilters = useMemo(() => filters?.filter(({ visibility }) => visibility === 'visible'), [filters]);

  useEffect(() => {
    if (!initialized && !isLoadingCustomFilters) {
      dispatch(loadCustomFilters({ network }));
    }
  }, [network, dispatch, isLoadingCustomFilters, initialized]);

  return {
    customFilters: customFilters.filters,
    visibleCustomFilters,
    initialized,
    isLoading: isLoadingCustomFilters,
  };
};

export default useCustomFilters;
