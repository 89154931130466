import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Button } from '@getro/rombo';
import { ChevronLeft, ChevronRight, ListFilter, Search, X } from 'lucide-react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import FilterItem from './filterItem';
import { SearchInput } from '../../atoms/searchInput';
import Badge from '../../atoms/badge';
import { getAPCAColor } from '../../../helpers/color';
import CompensationFilter from '../compensationFilter';

const FilterModal = ({
  filterOptions,
  tags,
  updateFilters,
  total,
  searchValue,
  onInputChange,
  resetFilters,
  hasAdvanceDesign,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const filter = selectedFilter ? filterOptions.find((item) => item.queryParam === selectedFilter) : null;
  const ref = useRef();
  const close = () => {
    setIsOpen(false);
    setSelectedFilter(null);
  };
  const colors = getAPCAColor(hasAdvanceDesign);

  const selectedCounts = useMemo(
    () =>
      tags.reduce((acc, item) => {
        acc.set(item.queryParam, item.values.length);
        return acc;
      }, new Map()),
    [tags],
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && isOpen) {
      document.querySelector('body').style.overflow = 'hidden';
    }

    return () => {
      document.querySelector('body').style.overflow = null;
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Modal
          ref={ref}
          actions={
            <Button
              size="large"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '48px',
                bg: colors.primary,
                color: colors.accent,
                '&:hover': {
                  bg: colors.primary,
                  color: colors.accent,
                },
              }}
              onClick={close}
            >
              View{total > 0 ? ` ${total.toLocaleString('en-US')} ` : ' '}
              {total === 1 ? 'result' : 'results'}
            </Button>
          }
          title={
            <Flex justifyContent="center">
              <Box
                onClick={() => setSelectedFilter(null)}
                size="24px"
                sx={{
                  visibility: selectedFilter ? 'visible' : 'hidden',
                  color: 'neutral.900',
                  cursor: 'pointer',
                }}
                as={ChevronLeft}
              />
              <Box
                sx={{
                  flex: 1,
                  ':fist-letter': {
                    textTransform: 'uppercase',
                  },
                }}
              >
                {filter ? filter.name : 'Filters'}
              </Box>
              {selectedFilter && selectedCounts.get(selectedFilter) > 0 && (
                <Box
                  onClick={() => {
                    resetFilters(filter);
                  }}
                  sx={{
                    color: 'text.subtle',
                    cursor: 'pointer',
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: '400',
                  }}
                  as="p"
                >
                  Clear all
                </Box>
              )}
              {!selectedFilter && (
                <Box
                  data-testid="close-modal"
                  onClick={close}
                  size="24px"
                  sx={{
                    color: 'neutral.400',
                    cursor: 'pointer',
                  }}
                  as={X}
                />
              )}
            </Flex>
          }
          variant="mobile"
          onCancel={close}
        >
          {selectedFilter && (
            <>
              {selectedFilter === 'compensation' ? (
                <CompensationFilter
                  tag={tags.find((item) => item.queryParam === selectedFilter) ?? { values: [] }}
                  filter={filter}
                  updateFilters={updateFilters}
                  hasAdvanceDesign={hasAdvanceDesign}
                  autoFocus={false}
                />
              ) : (
                <FilterItem
                  tag={tags.find((item) => item.queryParam === selectedFilter) ?? { values: [] }}
                  filter={filter}
                  updateFilters={updateFilters}
                  hasAdvanceDesign={hasAdvanceDesign}
                  autoFocus={false}
                />
              )}
            </>
          )}
          {!selectedFilter && (
            <Flex sx={{ flexDirection: 'column', gap: '16px', p: '16px' }}>
              <SearchInput
                leftIcon={Search}
                rightIcon={X}
                data-testid="textInput"
                type="text"
                value={searchValue}
                placeholder="Job title, company or keyword"
                onChange={(value) => onInputChange(value)}
                onRightIconClicked={() => onInputChange('')}
                sx={{ borderColor: '#DEE4ED!important' }}
                size="xLarge"
              />
              <Box>
                {filterOptions.map((item) => (
                  <Flex
                    onClick={() => {
                      setSelectedFilter(item.queryParam);
                    }}
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      py: '12px',
                      cursor: 'pointer',
                      color: 'text.main',
                      fontSize: '16px',
                      lineHeight: '24px',
                    }}
                  >
                    <Box>{item.name}</Box>
                    <Flex sx={{ alignItems: 'center', gap: '4px' }}>
                      {selectedCounts.get(item.queryParam) > 0 && (
                        <Badge hasAdvanceDesign={hasAdvanceDesign}>{selectedCounts.get(item.queryParam)}</Badge>
                      )}
                      <Box size="24px" as={ChevronRight} />
                    </Flex>
                  </Flex>
                ))}
              </Box>
            </Flex>
          )}
        </Modal>
      )}
      <Button
        sx={{
          display: ['flex', 'flex', 'none'],
          justifyContent: 'center',
        }}
        icon={ListFilter}
        variant="secondary"
        width="100%"
        iconGap="8px"
        height={['40px', '40px', '32px']}
        onClick={() => setIsOpen(true)}
      >
        All filters
      </Button>
    </>
  );
};

FilterModal.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  searchValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  hasAdvanceDesign: PropTypes.bool.isRequired,
};

export default FilterModal;
