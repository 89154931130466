import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { Check, ChevronDown, ChevronUp, MapPin, X } from 'lucide-react';
import PropTypes from 'prop-types';
import { SearchInput } from '../../atoms/searchInput';
import useOnClickOutside from '../../../hooks/useClickOutside';
import { Card } from '../../atoms/card';
import { PageLoader } from '../../atoms/pageLoader';
import { getAPCAColor } from '../../../helpers/color';

export const LocationFilter = ({
  locations,
  selectedLocations,
  onSearchChange,
  updateFilters,
  term,
  isLocationSuggestionsLoading,
  hasAdvancedDesign,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useOnClickOutside(() => {
    setShowDropdown(false);
  });

  const locationResults = useMemo(
    () =>
      locations.map((item) => ({
        name: item.value,
        value: item.value,
      })),
    [locations],
  );

  const selected = useMemo(() => {
    const set = new Set();
    selectedLocations.forEach((item) => {
      if (!set.has(item.id)) {
        set.add(item.id);
      }
    });

    return set;
  }, [selectedLocations]);

  const baseTerm = useMemo(
    () =>
      selectedLocations.length > 0
        ? `${selectedLocations[0].name} ${
            selectedLocations.length > 1 ? `· And ${selectedLocations.length - 1} more` : ''
          }`
        : '',
    [selectedLocations],
  );

  return (
    <Box
      data-testid="location-filter"
      ref={ref}
      width="100%"
      sx={{
        position: 'relative',
        svg: {
          ml: '0px',
        },
      }}
    >
      {!showDropdown && (
        <SearchInput
          data-testid="location-search"
          onFocus={() => {
            setShowDropdown(true);
            onSearchChange('');
          }}
          placeholder="Location"
          leftIcon={MapPin}
          height="56px"
          value={baseTerm}
          sx={{
            transition: 'none',
            textTransform: 'none',
          }}
          rightIcon={ChevronDown}
          showRightIconOnValue={false}
          onRightIconClicked={(e) => {
            e.stopPropagation();
            setShowDropdown(!showDropdown);
            if (term?.length === 0) {
              onSearchChange('');
            }
          }}
        />
      )}
      {showDropdown && (
        <Card
          padding="0px"
          sx={{
            position: 'absolute',
            zIndex: 9999,
            width: '100%',
          }}
        >
          {selectedLocations.length > 0 && (
            <Flex sx={{ gap: '4px', py: '12px', px: '16px' }}>
              <Flex sx={{ height: '100%', mt: '8px', marginRight: '8px' }}>
                <Box
                  as={MapPin}
                  size="16px"
                  sx={{
                    cursor: 'pointer',
                    color: 'neutral.500',
                    ml: '2px',
                    maxWidth: '16px',
                    minWidth: '16px',
                  }}
                />
              </Flex>
              <Flex sx={{ flexWrap: 'wrap', gap: '4px', flex: 1 }}>
                {selectedLocations.map((item) => (
                  <Flex
                    key={item.value}
                    sx={{
                      gap: '4px',
                      alignItems: 'center',
                      padding: '0px 8px',
                      justifyContent: 'space-between',
                      width: 'fit-content',
                      borderRadius: '4px',
                      border: '1px solid',
                      borderColor: 'border.subtle',
                      height: '32px',
                    }}
                  >
                    <Box
                      as="p"
                      sx={{
                        fontSize: '14px',
                        color: 'text-main',
                        lineHeight: '22px',
                        padding: '8px 0px',
                        'white-space': 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.name}
                    </Box>
                    <Box
                      onClick={() => {
                        const filter = {
                          queryParam: 'searchable_locations',
                          value: item.id || item.value,
                          name: item.name,
                        };
                        updateFilters({ filter, mode: 'remove' });
                      }}
                      as={X}
                      size="16px"
                      sx={{
                        cursor: 'pointer',
                        color: 'text.subtle',
                      }}
                    />
                  </Flex>
                ))}
              </Flex>
              <Box
                onClick={() => {
                  setShowDropdown(false);
                }}
                size="16px"
                as={ChevronUp}
                sx={{ color: 'text.subtle', cursor: 'pointer', minWidth: '16px', mt: '8px' }}
              />
            </Flex>
          )}
          <SearchInput
            value={term}
            data-testid="location-search-filter"
            onChange={(value) => {
              onSearchChange(value);
            }}
            autoFocus
            sx={{
              border: '1px solid transparent!important',
              borderBottom: selectedLocations.length > 0 ? '1px solid' : '0px',
              borderBottomColor: selectedLocations.length > 0 ? '#DEE4ED!important' : 'initial',
              transition: 'none',
            }}
            mx={selectedLocations.length ? '16px' : '0px'}
            p={!selectedLocations.length ? '8px 12px 8px 40px' : '0px'}
            height={selectedLocations.length ? '40px' : '56px'}
            placeholder="Type to search"
            leftIcon={selectedLocations.length ? null : MapPin}
            rightIcon={selectedLocations.length ? null : ChevronUp}
            showRightIconOnValue={false}
            onRightIconClicked={(e) => {
              e.stopPropagation();
              setShowDropdown(!showDropdown);
            }}
          />
          <Box p="8px">
            {!isLocationSuggestionsLoading &&
              locationResults?.map(({ value, name }) => (
                <Flex
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: selected.has(value) ? 'neutral.20' : 'none',
                    cursor: 'pointer',
                    px: '8px',
                    mb: '2px',
                  }}
                  onClick={() => {
                    if (selected.has(value)) {
                      const filter = {
                        queryParam: 'searchable_locations',
                        value,
                        name: value,
                      };
                      updateFilters({ filter, mode: 'remove' });
                    } else {
                      updateFilters({ filter: { queryParam: 'searchable_locations', value, name: value } });
                      if (term) {
                        onSearchChange('');
                      }
                    }
                  }}
                >
                  <Box
                    key={value}
                    as="p"
                    sx={{
                      fontSize: '14px',
                      color: selected.has(value) ? 'text.dark' : 'text.main',
                      lineHeight: '1.4',
                      padding: '8px 0px',
                    }}
                  >
                    {name}
                  </Box>
                  {selected.has(value) && (
                    <Box as={Check} size="16px" sx={{ color: getAPCAColor(hasAdvancedDesign).primary }} />
                  )}
                </Flex>
              ))}

            {(isLocationSuggestionsLoading || locationResults.length === 0) && (
              <Flex sx={{ height: 200, justifyContent: 'center' }}>
                {isLocationSuggestionsLoading ? (
                  <PageLoader data-testid="loading" color={getAPCAColor(hasAdvancedDesign).color} />
                ) : (
                  <Box
                    sx={{
                      color: 'text.subtle',
                      fontSize: '14px',
                      fontStyle: 'italic',
                      mt: '20px',
                    }}
                    as="p"
                  >
                    No results
                  </Box>
                )}
              </Flex>
            )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

LocationFilter.propTypes = {
  locations: PropTypes.array,
  onSearchChange: PropTypes.func.isRequired,
  selectedLocations: PropTypes.array,
  term: PropTypes.string.isRequired,
  isLocationSuggestionsLoading: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  hasAdvancedDesign: PropTypes.bool.isRequired,
};

LocationFilter.defaultProps = {
  locations: [],
  selectedLocations: [],
};
