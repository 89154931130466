import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Input } from '@getro/rombo';

export const SearchInput = forwardRef(
  (
    {
      placeholder,
      leftIcon,
      leftIconSx,
      rightIcon,
      sx,
      value,
      onRightIconClicked,
      onChange,
      showRightIconOnValue,
      ...props
    },
    ref,
  ) => (
    <Flex sx={{ alignItems: 'center', width: '100%', position: 'relative' }}>
      {leftIcon && (
        <Box
          variant="icon"
          color="neutral.500"
          sx={{ stroke: 'currentColor', position: 'absolute', left: '16px', ...leftIconSx }}
          data-item="close-icon"
          height="16px"
          width="16px"
          strokeWidth="1.5"
          aria-hidden="true"
          as={leftIcon}
        />
      )}

      <Input
        placeholder={placeholder}
        value={value}
        sx={{
          fontFamily: 'inherit!important',
          pl: leftIcon ? '40px' : '16px',
          borderRadius: '4px',
          border: '1px solid',
          borderColor: '#8797AB !important',
          fontSize: '16px',
          color: '',
          '::placeholder': {
            color: 'text.placeholder',
            fontSize: '16px',
          },
          ...sx,
        }}
        onChange={(e) => onChange(e.target.value)}
        ref={ref}
        {...props}
      />
      {((rightIcon && showRightIconOnValue && value) || (rightIcon && !showRightIconOnValue)) && (
        <Box
          data-testid="search-clear"
          display="flex"
          alignItems="center"
          color="neutral.500"
          aria-hidden="true"
          sx={{
            stroke: 'neutral.500',
            background: 'transparent',
            position: 'absolute',
            right: '16px',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            if (onChange) {
              onChange('');
            }
            if (onRightIconClicked) onRightIconClicked(e);
          }}
        >
          <Box
            variant="icon"
            color="neutral.500"
            sx={{ stroke: 'currentColor' }}
            size="16px"
            data-item="clear-icon"
            aria-hidden="true"
            strokeWidth="1.5"
            height="1em"
            width="1em"
            as={rightIcon}
          />
        </Box>
      )}
    </Flex>
  ),
);

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  rightIcon: PropTypes.any,
  leftIcon: PropTypes.any,
  sx: PropTypes.object,
  leftIconSx: PropTypes.object,
  value: PropTypes.string.isRequired,
  onRightIconClicked: PropTypes.func,
  onChange: PropTypes.func,
  showRightIconOnValue: PropTypes.bool,
};

SearchInput.defaultProps = {
  rightIcon: null,
  leftIcon: null,
  sx: {},
  leftIconSx: {},
  onRightIconClicked: null,
  onChange: null,
  showRightIconOnValue: true,
};

export default Input;
