import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Input } from '@rebass/forms/styled-components';

import { getAPCAColor } from '../../../helpers/color';

const Range = ({ value, min, max, step, onChange, hasAdvanceDesign }) => {
  const fixedValue = value > max ? max : value;
  const percentage = useMemo(() => (100 * (fixedValue - min)) / (max - min), [fixedValue, min, max]);
  const minValRef = useRef(null);
  const color = hasAdvanceDesign ? getAPCAColor(hasAdvanceDesign).primary : '#8A75F0';

  return (
    <Box sx={{ height: '20px' }}>
      <Box
        sx={{
          position: 'relative',
          input: {
            border: 0,
            position: 'absolute',
            outline: 'none',
            px: 0,
            pointerEvents: 'none',
            backgroundColor: 'transparent',
            zIndex: 3,
            color,
            '&::-webkit-slider-thumb': {
              appearance: 'none',
              background: 'currentcolor',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              height: '16px',
              width: '16px',
              pointerEvents: 'all',
              position: 'relative',
            },
            '::-moz-range-thumb': {
              appearance: 'none',
              background: 'currentcolor',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              height: '20px',
              width: '20px',
              pointerEvents: 'all',
              position: 'relative',
            },
          },
        }}
      >
        <Input
          data-testid="input-range"
          type="range"
          ref={minValRef}
          min={min}
          max={max}
          step={step}
          height="0"
          value={fixedValue}
          onChange={(e) => onChange(+e.target.value)}
        />
      </Box>
      <Box pt="6px" sx={{ position: 'relative' }}>
        <Box height="4px" width="100%" sx={{ zIndex: 1, top: '6px', bg: 'neutral.100' }} />
        <Box height="4px" sx={{ position: 'absolute', zIndex: 2, top: '6px', bg: color, width: `${percentage}%` }} />
      </Box>
    </Box>
  );
};

Range.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  step: PropTypes.number,
  hasAdvanceDesign: PropTypes.bool,
};

Range.defaultProps = {
  value: 0,
  step: 1,
  hasAdvanceDesign: false,
};

export default Range;
