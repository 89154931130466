import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Loader, VirtualizedList } from '@getro/rombo';
import { X } from 'lucide-react';
import ResizeObserver from 'resize-observer-polyfill';
import { SearchInput } from '../../atoms/searchInput';

export const FilterList = ({
  canFilterResults,
  filterFunction,
  options,
  itemTemplate,
  sort,
  searchValue,
  onFilter,
  isLoading,
  autoFocus,
}) => {
  const [filter, setFilter] = useState(searchValue ?? '');
  const ref = useRef();
  const maxHeight = 400;

  const filteredOptions = useMemo(() => filterFunction({ filter, options }), [filter, filterFunction, options]);

  const filteredList = useMemo(() => {
    if (sort === false && !filter) {
      return options;
    }

    if (sort === false) {
      return filteredOptions;
    }

    return filteredOptions;
  }, [filter, filteredOptions, options, sort]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect.width > 1040) {
          const calcHeight = filteredOptions.length * 38;
          setHeight(calcHeight > maxHeight ? maxHeight : calcHeight);
        } else {
          setHeight(filteredOptions.length * 50);
        }
      });
    });

    const target = document.body;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [filteredOptions.length]);

  useEffect(() => {
    if (ref.current && autoFocus) {
      setTimeout(() => {
        ref.current?.focus?.();
      }, 100);
    }
  }, [autoFocus]);

  return (
    <Flex
      py={['16px', '16px', '8px']}
      data-testid="filter-list"
      flexDirection="column"
      flexGrow={1}
      sx={{ overflow: 'hidden' }}
    >
      {canFilterResults && (
        <Box px="16px" pb={['0', '0', '16px']} pt={['0px', 'opx', '8px']} data-testid="search-filter">
          <SearchInput
            ref={ref}
            rightIcon={X}
            data-testid="textInput"
            type="text"
            value={onFilter ? searchValue : filter}
            placeholder="Type to search"
            height={['48px', '48px', '40px']}
            onChange={(value) => {
              if (onFilter) {
                onFilter(value);
                return;
              }

              setFilter(value);
            }}
            sx={{ borderColor: '#DEE4ED!important', p: '10px 12px' }}
          />
        </Box>
      )}
      {filteredList.length > 0 && !isLoading && (
        <Flex
          data-testid="filter-results"
          overflow="hidden"
          flexDirection="column"
          sx={{
            overflowY: 'auto',
            maxHeight: `${height}px`,
          }}
        >
          <Box
            width={1}
            sx={{
              height: `${height}px`,
              '& > div': {
                flexDirection: 'column',
                overflowY: ['hidden!important', 'hidden!important', 'auto!important'],
                '&::-webkit-scrollbar': {
                  color: 'red',
                  width: '3px',
                },
                '&::-webkit-scrollbar-track': {},
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10px',
                  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
                },
                '&::-webkit-scrollbar-bar': {
                  width: '12px',
                },
                scrollbarWidth: '3px',
              },
            }}
            data-testid="virtualized-wrapper"
          >
            <VirtualizedList
              initialItemCount={3}
              items={filteredList.map((item) => ({ children: itemTemplate(item) }))}
            />
          </Box>
        </Flex>
      )}
      {isLoading && (
        <Box mt="16px">
          <Loader />
        </Box>
      )}
      {!isLoading &&
        ((options && options.length > 0 && filteredList.length === 0) ||
          (canFilterResults && options.length === 0)) && (
          <Text
            data-testid="empty-results"
            sx={{
              color: 'text.subtle',
              textAlign: 'center',
              fontSize: '14px',
              lineHeight: '1.4',
              pt: '8px',
              pb: '16px',
              fontStyle: 'italic',
            }}
          >
            No results
          </Text>
        )}
    </Flex>
  );
};

FilterList.propTypes = {
  options: PropTypes.array,
  itemTemplate: PropTypes.func.isRequired,
  filterFunction: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  canFilterResults: PropTypes.bool,
  sort: PropTypes.bool,
  isLoading: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

FilterList.defaultProps = {
  options: [],
  canFilterResults: true,
  sort: null,
  isLoading: false,
  autoFocus: true,
};
