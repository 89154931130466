import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Checkbox, FormLabel, Input, Select } from '@getro/rombo';

import { COMPENSATION_CURRENCY_VALUES, COMPENSATION_PERIOD_VALUES } from '../../../constants';
import Range from '../../atoms/range';
import { convertFromCents, convertToCents } from '../../../helpers/currency';

const CompensationFilter = ({ tag, filter, updateFilters, hasAdvanceDesign, autoFocus }) => {
  const firstRender = useRef(true);
  const amountMinRef = useRef(null);
  const [currency, setCurrency] = useState(
    tag?.values?.[0]?.currency
      ? COMPENSATION_CURRENCY_VALUES.find((c) => c.value === tag.values[0].currency)
      : { label: 'USD', value: 'USD' },
  );
  const [period, setPeriod] = useState(
    tag?.values?.[0]?.period
      ? COMPENSATION_PERIOD_VALUES.find((p) => p.value === tag.values[0].period)
      : { label: 'Per year', value: 'year' },
  );
  const [amountMin, setAmountMin] = useState(
    tag?.values?.[0]?.amount_min_cents && convertFromCents(tag.values[0].amount_min_cents),
  );
  const [offersEquity, setOffersEquity] = useState(tag?.values?.[0]?.offers_equity || false);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      updateFilters({
        filter: {
          queryParam: filter.queryParam,
          value: {
            currency: currency.value,
            period: period.value,
            amount_min_cents: amountMin ? convertToCents(amountMin) : 0,
            offers_equity: offersEquity,
          },
          name: filter.name,
        },
        mode: currency.value === 'USD' && period.value === 'year' && !amountMin && !offersEquity ? 'remove' : 'add',
      });
    }
  }, [currency, period, amountMin, offersEquity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (amountMinRef.current && autoFocus) {
      setTimeout(() => {
        amountMinRef.current?.focus?.();
      }, 100);
    }
  }, [autoFocus]);

  return (
    <Box p="16px">
      <FormLabel>Minimum amount</FormLabel>
      <Flex
        sx={{
          input: { m: 0, borderColor: 'border.default' },
          position: 'relative',
          alignItems: 'center',
          svg: { color: 'neutral.400' },
          cursor: 'pointer',
          gap: '8px',
        }}
      >
        <Box flex="4">
          <Input
            ref={amountMinRef}
            placeholder="0"
            value={amountMin}
            onChange={(e) => {
              setAmountMin(e.target.value);
            }}
          />
        </Box>
        <Box flex="3">
          <Select
            data-testid="currency-select"
            options={COMPENSATION_CURRENCY_VALUES}
            value={currency}
            onChange={(newValue) => {
              setCurrency(newValue);
            }}
          />
        </Box>
        <Box flex="4">
          <Select
            data-testid="period-select"
            options={COMPENSATION_PERIOD_VALUES}
            value={period}
            onChange={(newValue) => {
              setPeriod(newValue);
            }}
          />
        </Box>
      </Flex>
      <Box mt="8px">
        <Range
          min={0}
          max={300000}
          step={10000}
          value={amountMin ? parseInt(amountMin, 10) : 0}
          hasAdvanceDesign={hasAdvanceDesign}
          onChange={(rangeValue) => {
            setAmountMin(rangeValue);
          }}
        />
      </Box>
      <Box mt="8px">
        <Checkbox
          name="offers_equity"
          label="Only jobs offering equity"
          checked={offersEquity}
          onChange={(event) => {
            setOffersEquity(event.target.checked);
          }}
        />
      </Box>
    </Box>
  );
};

CompensationFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  hasAdvanceDesign: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

CompensationFilter.defaultProps = {
  hasAdvanceDesign: false,
  autoFocus: true,
};

export default CompensationFilter;
