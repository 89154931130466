import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { locationSuggestionsReset, locationSuggestionsRequest } from '../redux/actions/locationSuggestions';
import {
  isLocationSuggestionsLoadingSelector,
  locationSuggestionsSelector,
  locationSuggestionsTermSelector,
} from '../redux/selectors/locationSuggestions';

const useLocationSuggestions = ({ network, company, origin = 'jobs' }) => {
  const dispatch = useDispatch();
  const locationSuggestions = useSelector(locationSuggestionsSelector);
  const locationSuggestionsTerm = useSelector(locationSuggestionsTermSelector);
  const isLocationSuggestionsLoading = useSelector(isLocationSuggestionsLoadingSelector);

  // Clear the locations if any in memory so its fresh on each page
  useEffect(() => {
    dispatch(locationSuggestionsReset());
  }, [dispatch]);

  const loadLocationSuggestions = ({ term }) => {
    dispatch(locationSuggestionsRequest({ network, company, term, origin }));
  };

  const resetSearchTerm = () => dispatch(locationSuggestionsReset());

  return {
    resetSearchTerm,
    locationSuggestionsTerm,
    locationSuggestions,
    loadLocationSuggestions,
    isLocationSuggestionsLoading,
  };
};

export default useLocationSuggestions;
