import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { getAPCAColor } from '../../../helpers/color';

const Badge = ({ children, hasAdvanceDesign }) => (
  <Flex
    sx={{
      color: 'neutral.0',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      backgroundColor: getAPCAColor(hasAdvanceDesign).primary,
      minWidth: '16px',
      minHeight: '16px',
      width: '16px',
      height: '16px',
      borderRadius: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box>{children}</Box>
  </Flex>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  hasAdvanceDesign: PropTypes.bool,
};

Badge.defaultProps = {
  hasAdvanceDesign: false,
};

export default Badge;
