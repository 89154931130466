import PropTypes from 'prop-types';

export const CompanySchema = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  websiteUrl: PropTypes.string.isRequired,
  founded: PropTypes.string,
  angellistUrl: PropTypes.string,
  crunchbaseUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  hasActiveJobs: PropTypes.bool.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      placeId: PropTypes.string,
    }),
  ),
  approxEmployees: PropTypes.number,
});
