import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Card as RebassCard } from 'rebass/styled-components';

export const Card = forwardRef(({ children, sx, ...props }, ref) => (
  <RebassCard
    ref={ref}
    sx={{
      width: '216px',
      boxShadow: '0px 1px 8px 0px rgba(27, 36, 50, 0.10), 0px 4px 16px 0px rgba(27, 36, 50, 0.10)',
      borderRadius: '4px',
      ...sx,
    }}
    {...props}
  >
    {children}
  </RebassCard>
));

Card.defaultProps = {
  sx: {},
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};
